import '../styles/globals.css';
import Script from 'next/script';
import Head from 'next/head';

function MyApp({ Component, pageProps }) {
  return (
    <div>
      <Head>
      </Head>
      
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=G-5EPF2PWY4V`}
        strategy="beforeInteractive"
      />
  <Script id="google-analytics" strategy="beforeInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-5EPF2PWY4V');
        `}
      </Script>
      <Component {...pageProps} />
    </div>
  );
}

export default MyApp;
